import * as React from 'react';
import styled from '@emotion/styled';
import { navigate } from '../../components/wrapper-lang';
import { FormInput } from './form-input';
import { useUppy } from './useUppy';
const StyledForm = styled.form `
  display: flex;
  flex-wrap: wrap;

  & > button {
    flex: 0 1 auto;
    width: 100%;
    border: none;
    font-size: 1.25rem;
    padding: 0.75rem 1.25rem;
    color: ${({ theme }) => theme.bg};
    background: ${({ theme }) => theme.fg};
  }
`;
const getFormData = ($form, formDetail) => {
    return formDetail.map(item => {
        const $input = $form.elements[item.id];
        const value = $input ? $input.value : '';
        return {
            id: item.id,
            title: item.title,
            type: item.type,
            value,
        };
    });
};
export const FormBuilder = ({ formDetail, postUrl, }) => {
    const formRef = React.useRef();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const uppy = useUppy();
    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        let fileURL;
        try {
            const result = await uppy.upload();
            if (result.failed.length > 0) {
                setIsSubmitting(false);
                return;
            }
            else {
                fileURL = result.successful.map(file => file.uploadURL);
            }
        }
        catch (err) {
            console.log(err);
            setIsSubmitting(false);
            return;
        }
        // patch formdata with upload images
        const $form = formRef.current;
        const data = getFormData($form, formDetail);
        const uploadInput = data.find(item => item.type === 'upload');
        if (uploadInput) {
            uploadInput.value = fileURL;
        }
        const res = await fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (res.status == 200) {
            // pack things up
            $form.reset();
            uppy.reset();
            setIsSubmitting(false);
            navigate('/submit-success');
        }
        else {
            setIsSubmitting(false);
            const err = await res.json();
            console.error(err);
        }
    };
    return (<StyledForm ref={formRef} onSubmit={onSubmit}>
      {formDetail.map(input => (<FormInput uppy={uppy} input={input} key={input.id}/>))}
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </StyledForm>);
};
