import * as React from 'react';
export const InputText = ({ id, title, required }) => {
    return <input type="text" name={id} {...{ id, title, required }}/>;
};
export const InputTextarea = ({ id, title, required, }) => {
    return <textarea defaultValue={' '} name={id} {...{ id, title, required }}/>;
};
export const InputNumber = ({ id, title, required }) => {
    return <input type="number" name={id} {...{ id, title, required }}/>;
};
