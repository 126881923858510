import { useRef, useEffect } from 'react';
import Uppy from '@uppy/core';
import AS3 from '@uppy/aws-s3';
const getUploadParameters = async (file) => {
    const result = await fetch('/.netlify/functions/create-upload-url', {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
        },
        body: JSON.stringify({
            filename: file.name,
            contentType: file.type,
        }),
    })
        .then(res => res.json())
        .catch(err => console.error(err));
    return {
        method: 'PUT',
        url: result.url,
    };
};
export const useUppy = () => {
    const uppyRef = useRef(Uppy({
        restrictions: {
            maxFileSize: 2500000,
            maxNumberOfFiles: 4,
            minNumberOfFiles: 4,
            allowedFileTypes: ['image/*'],
        },
    }).use(AS3, {
        limit: 4,
        getUploadParameters,
    }));
    useEffect(() => {
        return () => {
            uppyRef.current.close();
        };
    }, []);
    return uppyRef.current;
};
