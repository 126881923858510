import * as React from 'react';
import styled from '@emotion/styled';
const StyledBoolean = styled.div `
  & > div {
    display: inline-block;
    margin-right: 1rem;
    padding: 0.5rem;
  }

  input,
  label {
    display: inline-block;
  }

  label {
    margin-left: 0.5rem;
  }
`;
const InputBooleanElement = props => {
    const { label, ...rest } = props;
    return (<div>
      <input {...rest}/>
      <label htmlFor={rest.id}>{label}</label>
    </div>);
};
const makeInputBoolean = ({ type, options, }) => {
    const renderInput = ({ id, required }) => Object.entries(options).map((option, i) => (<InputBooleanElement key={`${id}-${i}`} id={`${id}-${i}`} name={id} type={type} label={option[0]} value={option[1]} required={required}/>));
    const InputBoolean = props => {
        return <StyledBoolean>{renderInput(props)}</StyledBoolean>;
    };
    return InputBoolean;
};
export const InputBoolean = makeInputBoolean({
    type: 'radio',
    options: {
        유: 'yes',
        무: 'no',
    },
});
export const InputMilitary = makeInputBoolean({
    type: 'radio',
    options: {
        군필: '군필',
        미필: '미필',
        면제: '면제',
    },
});
export const InputGender = makeInputBoolean({
    type: 'radio',
    options: {
        남: 'male',
        여: 'female',
    },
});
