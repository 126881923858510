import * as React from 'react';
import styled from '@emotion/styled';
import { renderInput } from './input-render';
const StyledFormInput = styled.div `
  width: ${({ size }) => (100 / 12) * size}%;
  box-sizing: border-box;
  margin-bottom: 2.5rem;
  flex: 0 1 auto;

  & > label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.4;
  }

  & > label small {
    font-size: 0.8rem;
    opacity: 0.8;
  }

  & > input,
  & > textarea {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.line2};
    background: ${({ theme }) => theme.inputBg};
    color: ${({ theme }) => theme.inputFg};
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0.25rem;
    font-family: 'helvetica', sans-serif;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  & > input:focus,
  & > textarea:focus {
    border-bottom: 1px solid ${({ theme }) => theme.fg};
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.fg};
  }

  & > textarea {
    height: 6rem;
    resize: vertical;
  }
`;
export const FormInput = ({ input, uppy }) => {
    const { id, title, helpText, type, required, size } = input;
    return (<StyledFormInput size={size}>
      <label htmlFor={id}>
        <p>{title}</p>
        {helpText && (<p>
            <small>{helpText}</small>
          </p>)}
      </label>
      {renderInput(type, { title, required, id, uppy })}
    </StyledFormInput>);
};
