import * as React from 'react';
import { graphql } from 'gatsby';
import { FormBuilder } from '../components/form-builder';
import { FlexContent } from '../components/grid';
import SEO from '../components/seo';
import { H1, Header, MarkdownStyler, Layout, } from '../components/template.common';
import styled from '@emotion/styled';
const Divider = styled.hr `
  border: none;
  border-top: ${({ theme }) => theme.line};
  margin: 2rem 0;
`;
const AuditionForm = ({ data, pageContext }) => {
    const { title, formDetail, notes } = data.contentJson;
    const { lang } = pageContext;
    return (<Layout>
      <FlexContent>
        <Header>
          <SEO {...{
        title,
        lang,
    }}/>
          <H1>{title}</H1>
        </Header>
        <FormBuilder formDetail={formDetail} postUrl={'/.netlify/functions/handle-audition-form'}/>
        <Divider />
        <MarkdownStyler lang={lang} dangerouslySetInnerHTML={{
        __html: notes,
    }}/>
      </FlexContent>
    </Layout>);
};
export const query = graphql `
  query AuditionForm($lang: String!) {
    contentJson(type: { eq: "form/audition" }, lang: { eq: $lang }) {
      title
      formDetail {
        title
        helpText
        type
        id
        required
        size
      }
      notes
    }
  }
`;
export default AuditionForm;
