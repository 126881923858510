import * as React from 'react';
import styled from '@emotion/styled';
import Dashboard from '@uppy/react/lib/Dashboard';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
const StyledUploadContainer = styled.div `
  width: 100%;
`;
export const InputUpload = ({ uppy }) => {
    return (<StyledUploadContainer>
      <Dashboard width={768} height={400} uppy={uppy} hideUploadButton={true}/>
    </StyledUploadContainer>);
};
