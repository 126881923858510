import * as React from 'react';
import { InputText, InputTextarea, InputNumber } from './input-text';
import { InputBoolean, InputMilitary, InputGender } from './input-boolean';
import { InputUpload } from './input-upload';
const INPUT_TYPE = {
    text: InputText,
    textarea: InputTextarea,
    number: InputNumber,
    boolean: InputBoolean,
    upload: InputUpload,
    'select-militery': InputMilitary,
    'select-gender': InputGender,
};
export const renderInput = (type, props) => {
    const InputType = INPUT_TYPE[type] || InputText;
    return <InputType {...props}/>;
};
